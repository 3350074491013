.nav-wrapper {
	/*height: 50px;*/
	font-size: 12px;
	/*line-height: 24px;*/
	padding-left: 12px;
	/*height: 42px;*/
	line-height: 42px;
}

.processing-time {
	font-size: 13px;
	line-height: 42px;
	color: black;
	display: none;
	float: right;
	margin-right: 10px;
}

.nav-wrapper span.text {
	display: none;
}

@media only screen and (min-width: 600px) {
	.processing-time {
		display: inline-block;
	}

	.nav-wrapper span.text {
		display: inline;
	}
}