* {
  box-sizing: border-box;
}

body {
  font-family: Verdana,Geneva,sans-serif;
}

@media screen and (min-width: 1200px) {
	.page-wrapper {
		width: 80%;
		margin: 0 auto;
	}
}