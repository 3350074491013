.footer {
	color: black;
	font-size: 13px;
	padding: 10px 0 30px;
	text-align: center;
}

.footer a {
	text-decoration: none;
	color: black;
	margin: 5px;
}

.footer a:hover {
	text-decoration: none;
	color: #2c5999;
}