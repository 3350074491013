.about-content {
	padding: 30px 50px;
	font-size: 14px;
	color: #5c5c5c;
	background-color: #f6f6ef;
	margin: 0;
}

.about-content h3 {
	font-size: 1.3em;
	margin: 1em 0 0.3em;
	color: black;
	font-weight: 200;
}

.about-content a {
	color: #477dca;
	text-decoration: none;
}

.about-content a:hover {
	color: #3264ac;
}

.about-title {
	font-size: 18px;
	margin: 10px;
	margin-left: 7px;
	color: #5c5c5c;
	font-weight: 200;
}