.dropdown {
    position: relative;
    display: inline-block;
    /*margin-left: 100px;*/
    line-height: 12px;
}
 ul.dropdown-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    /*top:45px;*/
    right:-2px;
    width: 95px;
    border-radius: 5px;
    border: 1px solid #9d9d9d;
    background-color: white;
    /*font-weight:bold;*/
    position: absolute;

    box-shadow: 0 0 5px rgba(0,0,0,.1);
    z-index: 1;
    line-height: 14px;
    font-size: 13px;
}
 ul.dropdown-list:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 100%;
    right: 13px;
    border-width: 0 8px 8px;
    border-style: solid;
    border-color: transparent transparent #9d9d9d transparent;
 }
 ul.dropdown-list:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 100%;
    right: 15px;
    border-width: 0 6px 6px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
 }
 ul.dropdown-list li a {
    color: #5c5c5c;
    text-decoration: none;
    display: block;
}
ul.dropdown-list li {
    padding: 8px 8px;
    border-bottom: 1px solid #e6e8ea;
}
ul.dropdown-list li:last-child {
    border-bottom: none;
}
ul.dropdown-list li a:hover {
    /*background-color: #e5e5e5;*/
    color: #ff742b;
}
 .button{
    /*width:178px;*/
    /*height:18px;*/
    background-color:#fff ;
    padding:5px;
    padding-right: 25px;
    margin: 6px;
    border-radius:3px;
    border: solid 1px #c3c3c3;
    /*font-weight:bold;*/
    color: #5c5c5c;
    font-size: 13px;
}
 .button:before{
    content:"";
    position:absolute;
    width:0px;
    height:0px;
    border: 5px solid;
    border-color: #9d9d9d transparent transparent transparent;
    right:15px;
    top:15px;
}